import React from "react"

function Footer() {
  return (
    <div>
      <footer className="py-8" style={{ backgroundColor: "#000" }}>
        <div className="container text-center">
          <p>Fortius Films | © Copyright {new Date().getFullYear()} </p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
