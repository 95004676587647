import React from "react";
import "../global.css";
import { Helmet } from "react-helmet";
import LandingPage from "../subpages/LandingPage";
import { useStaticQuery, graphql } from "gatsby"
// import Services from "../subpages/Services";
import Awards from "../subpages/Awards";
// import End from "../subpages/End";
import Footer from "../components/Footer";

function Index() {
  const { backgroundImage, logoH, logoV, mockYoutube } = useStaticQuery(graphql`
    {
      pageDataYaml {
        welcomeText
        landingText
      }
      mockYoutube: file(base: { eq: "mockyoutube.png" }) {
       publicURL
       childImageSharp {
          fluid(quality: 75, maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { childImageSharp: { fluid: { src:coverImage } } } = mockYoutube;


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>His Name Is Randy - The Film</title>
        <link rel="canonical" href="https://www.hisnameisrandy.com/" />
        <meta property="og:url" content="https://www.hisnameisrandy.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="His Name Is Randy" />
        <meta property="og:description" content="A film about an enigmatic street musician. A story of perseverance and the kindness of strangers, despite singing way too loud (and occasionally off-key)." />
        <meta property="og:image" content={`https://www.hisnameisrandy.com${coverImage}`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="hisnameisrandy.com" />
        <meta property="twitter:url" content="https://www.hisnameisrandy.com/" />
        <meta name="twitter:title" content="His Name Is Randy" />
        <meta name="twitter:description" content="A film about an enigmatic street musician. A story of perseverance and the kindness of strangers, despite singing way too loud (and occasionally off-key)." />
        <meta name="twitter:image" content={`https://www.hisnameisrandy.com${coverImage}`} />
      </Helmet>
      <LandingPage/>
      {/* <Services/> */}
      <Awards/>
      <Footer/>
    </>
  )
}

export default Index
