/** @jsx jsx */
// import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css, jsx } from '@emotion/react';

function Awards() {
  const { allAwardsYaml, backgroundImage } = useStaticQuery(graphql`
    {
      allAwardsYaml {
        edges {
          node {
            name
            image {
              id
              absolutePath
              publicURL
              childImageSharp {
                  fluid(quality: 75, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
            url
          }
        }
      }
      backgroundImage: file(base: { eq: "randy-skyline-bg.jpg" }) {
        publicURL
        childImageSharp {
          fluid(quality: 75, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const { childImageSharp: { fluid: { srcWebp: backgroundUrl } } } = backgroundImage;


  const styles = css`
    background-image: url(${ backgroundUrl });
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-attachment: fixed; */
    color: white;
    background-color: rgba(45,118,181, 0.5);
    background-blend-mode: multiply;
    box-shadow: 0px 5px 25px rgba(0,0,0,.8) inset;
    min-height: 20vh;

    .awards {
      padding:5rem 0;
      display: flex;
    }

    .award {
      /* width: 25%; */
      img {
        filter: brightness(0) invert(1) drop-shadow(5px 12px 12px rgba(0,0,0,.9));
      }
    }
  `;

  return (
    <div className="container-fluid" css={styles}>

      <div className="container w-full md:w-4/5 lg:w-3/5 awards mx-auto flex flex-wrap">
        {allAwardsYaml.edges.map(({ node }) => {
          const { name, url, image } = node;
          const { childImageSharp: { fluid: { srcWebp: awardImgUrl } } } = image;

          return(
            <div key={name} className="award w-1/2 md:w-1/3 lg:w-1/4 mb-12">
              <a target="_blank" rel="noopener noreferrer" href={url}>
                <img src={awardImgUrl} width="100" alt={name} className="w-10/12 mx-auto" />
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Awards
