/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import { css, jsx } from '@emotion/react';
// import BackgroundImage from 'gatsby-background-image';

function LandingPage({ data }) {
  const { backgroundImage, logoH, logoV, mockYoutube } = useStaticQuery(graphql`
    {
      pageDataYaml {
        welcomeText
        landingText
      }
      backgroundImage: file(base: { eq: "randy-background-image.jpg" }) {
       publicURL
       childImageSharp {
          fluid(quality: 75, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mockYoutube: file(base: { eq: "mockyoutube.png" }) {
       publicURL
       childImageSharp {
          fluid(quality: 75, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logoH: file(base: { eq: "word-mark-horiz.png" }) {
       publicURL
       childImageSharp {
          fluid(quality: 75, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logoV: file(base: { eq: "word-mark-vert.png" }) {
       publicURL
       childImageSharp {
          fluid(quality: 75, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  // const { welcomeText, landingText } = pageDataYaml
  const { childImageSharp: { fluid: { srcWebp:backgroundImg, srcSetWebp:backgroundImgSet }} } = backgroundImage;
  const { childImageSharp: { fluid: { srcWebp: logoUrlH } } }  = logoH;
  const { childImageSharp: { fluid: { srcWebp: logoUrlV } } } = logoV;
  const { childImageSharp: { fluid: { srcSetWebp: mockYoutubeSrcWeb } } } = mockYoutube;

  const styles = css`
    min-height: 65vh;
    display: inline-block;
    /* background-image: ${`image-set(${backgroundImgSet.split(",").join("','")})`}; */
    background-image: ${`url(${backgroundImg})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: fixed;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;

    .logo {
      filter: drop-shadow(5px 12px 12px rgba(0,0,0,.9));
      margin-top:5%;

      &.sm {

      }

      &.lg {
        /* width:90%; */
      }
    }

    .intro-text, .watch-text {
      background: rgba(0,0,0,.8);
      padding:1.5%;
    }

    .watch-text:hover {
      h2 {
        color:#FFF;
        background:transparent;
        background-clip: none;
        -webkit-background-clip: none;
        -moz-background-clip: none;
        -moz-text-fill-color: #FFF;
        -webkit-text-fill-color: #FFF;
        cursor:pointer;
      }
    }

    h2 {
      font-family: 'Beth Ellen', cursive;
      font-size: 2rem;
      line-height:180%;
      margin-bottom:1rem;
      /* color: #c14b24; */
      background: linear-gradient(45deg, #c14b24, #af4261);
      background-clip: text;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
    }

    .video-container {

      .video-wrap {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  `;

  return (
    // <BackgroundImage
    //   Tag="section"
    //   className={className}
    //   fluid={imageData}
    //   backgroundColor={`#040e18`}
    // >
      <section css={styles} className="container-fluid">
        <div className="mx-auto container">
          <img className="hidden mx-auto lg:block logo lg w-3/4" src={logoUrlH} alt="his name is randy" />
          <img className="block mx-auto lg:hidden logo sm w-3/4" src={logoUrlV} alt="his name is randy" />

          <div className="intro-text container w-9/12 mx-auto text-center transform -rotate-1">
            <h2>A movie about talking to strangers...</h2>
            <p>
              Everyday, this enigmatic street musician sings for a sea of people one at a time, many too busy to notice.
              Most don't know his name, and no one would ever believe his story. This film couldn't cover the half of it.
              This movie is a story of perseverance, knowing yourself, and the overwhelming kindness of
              strangers, despite singing way too loud.
            </p>
          </div>

          <div className="mx-auto container video-container mt-20 mb-16 text-center">
            <a href="#watch">
              <div className="watch-text w-8/12 md:w-5/12 lg:w-4/12 xl:w-3/12 mb-4 mx-auto text-center transform rotate-2">
                <h2>Watch it now!</h2>
              </div>
            </a>
            <div className="video-wrap">
              <a name="watch" href="https://www.youtube.com/watch?v=sM1mctuC3tw" target="_blank" rel="noopener noreferrer">
                <img srcSet={mockYoutubeSrcWeb} alt="watch his name is randy!"/>
                {/* <iframe src="https://www.youtube.com/embed/sM1mctuC3tw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              </a>
            </div>
          </div>
        </div>
      </section>
    // </BackgroundImage>
  )
};

export default LandingPage
